import React from 'react';
import Layout from '../containers/Layout';
import Hero from '../containers/Hero';
import Label from '../components/Label';
import Title from '../components/Title';

const NotFoundPage = () => (
  <Layout>
    <Hero secondary>
      <Label>Page not found</Label>
      <Title>Sorry, this page doesn&#39;t exist...</Title>
    </Hero>
  </Layout>
);

export default NotFoundPage;
